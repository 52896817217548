
const Subscribe = () => {
    return (
    <div className="topic">
        <p className="lead">Terra Incognita Newsletter</p>

    </div>
    );
};

export default Subscribe;
